.App {
  font-family: sans-serif;
  width: 50%;
  margin: auto;
  display: block;
}

.no-bitting-img {
  height: 90px;
  width: 90px;
}
.app-header {
  text-align: center;
}
footer {
  text-align: center;
  display: block;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.grid-item {
  border: 5px solid black;
  padding: 15px;
  display: grid;
  place-items: center;
}
@media screen and (max-width: 480px) {
  body {
    background-color: rgb(130, 197, 219);
    display: block;
    margin-left: -25%;
    font-family: sans-serif;
    text-align: center;
  }
  .app-header {
    margin-left: 45%;
    text-align: center;
    font-size: 22px;
  }
  footer {
    text-align: center;
    display: block;
    margin-left: 100px;
    font-size: 11px;
  }
}
